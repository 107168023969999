import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Container } from 'reactstrap'
import { FaFacebookF, FaLinkedinIn, FaPhoneAlt } from 'react-icons/fa'

const OurTeam = () => {
  return (
    <section>
      <Container>
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading">Echipa noastra</h2>
            <h3 className="section-subheading text-muted">Ne puteti contacta pentru orice informatie</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4">
            <div className="team-member">
              <div className="team-member-img mx-auto rounded-circle overflow-hidden">
                <StaticImage imgClassName="p-2 rounded-circle" src="./our-team-img/auras.jpg" alt="Panait Auras" />
              </div>
              <h4>Panait Auras</h4>
              <br />
              <ul className="list-inline social-buttons">
                <li className="list-inline-item">
                  <a href="tel:0040728683604">
                    <FaPhoneAlt />
                    <span className="seo-hidden">Contact the recruiter</span>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.facebook.com/auras.panait.940" target="_blank" rel="noopener noreferrer">
                    <FaFacebookF />
                    <span className="seo-hidden">Contact the recruiter</span>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.linkedin.com/in/auras-panait-23a7a4133/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedinIn />
                    <span className="seo-hidden">Contact the recruiter</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="team-member">
              <div className="team-member-img mx-auto rounded-circle overflow-hidden">
                <StaticImage
                  imgClassName="p-2 rounded-circle"
                  src="./our-team-img/andreea.jpg"
                  alt="Ceamatu Andreea Elena Recruiting Agent"
                />
              </div>
              <h4>Ceamatu Andreea-Elena</h4>
              <br />
              <ul className="list-inline social-buttons">
                <li className="list-inline-item">
                  <a href="tel:0040730719323" target="_blank" rel="noopener noreferrer">
                    <FaPhoneAlt />
                    <span className="seo-hidden">Contact the recruiter</span>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.facebook.com/andreea.elena.7982" target="_blank" rel="noopener noreferrer">
                    <FaFacebookF />
                    <span className="seo-hidden">Contact the recruiter</span>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.linkedin.com/in/ceamatu-cristian-viorel-7a5469136/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedinIn />
                    <span className="seo-hidden">Contact the recruiter</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="team-member">
              <div className="team-member-img mx-auto rounded-circle overflow-hidden">
                <StaticImage
                  imgClassName="p-2 rounded-circle"
                  src="./our-team-img/cristi.jpg"
                  alt="Ceamatu Cristian Viorel Recruiting Agent"
                />
              </div>
              <h4>Ceamatu Cristian</h4>
              <br />
              <ul className="list-inline social-buttons">
                <li className="list-inline-item">
                  <a href="tel:0040725085231" target="_blank" rel="noopener noreferrer">
                    <FaPhoneAlt />
                    <span className="seo-hidden">Contact the recruiter</span>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.facebook.com/ceamatu.cristian" target="_blank" rel="noopener noreferrer">
                    <FaFacebookF />
                    <span className="seo-hidden">Contact the recruiter</span>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.linkedin.com/in/ceamatu-cristian-viorel-7a5469136/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedinIn />
                    <span className="seo-hidden">Contact the recruiter</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 offset-lg-2 text-center">
            <p className="text-muted">Ne puteti contacta pentru orice informatie</p>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default OurTeam
