import Layout from '@components/Layout'
import HomeMeta from '@components/meta/HomeMeta'
import * as React from 'react'

import About from '@components/sections/About'
import Apply from '@components/sections/Apply'
import Header from '@components/sections/Header'
import JobsSection from '@components/sections/JobsSection'
import Journey from '@components/sections/Journey'
// import Partners from '@components/sections/Partners'

const IndexPage = () => (
  <Layout>
    <HomeMeta />

    <Header />
    <About />
    <JobsSection />
    <Journey />
    <Apply />
    {/* <Partners /> */}
  </Layout>
)

export default IndexPage
