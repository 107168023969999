import React, { useState } from 'react'
import { Modal, ModalFooter, ModalBody } from 'reactstrap'

import { FaTimes } from 'react-icons/fa'

const ApplyModalSuccess = ({ phone, email }) => {
  const [modal, setModal] = useState(true)

  const toggle = (e) => {
    e.preventDefault()
    setModal(!modal)
  }

  return (
    <Modal isOpen={modal} toggle={toggle} centered={true}>
      <div className="py-4 px-2">
        <p className="text-center">Iti multumim pentru aplicatie! Te rugam sa verifici detaliile de contact</p>
        <ModalBody>
          <ul className="list-group my-1">
            <li className="list-group-item font-weight-bold">Telefon: {phone}</li>
            <li className="list-group-item font-weight-bold">Email: {email}</li>
          </ul>
        </ModalBody>

        <ModalFooter className="border-top-0">
          <p className="text-center">Daca nu te vom suna in urmatoarele zile te rugam sa contactezi telefonic.</p>
          <button type="button" className="btn btn-danger mx-auto" onClick={toggle}>
            <FaTimes /> Inchide
          </button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

export default ApplyModalSuccess
